import React, { useEffect, useMemo, useState } from 'react';

import Autosuggest from 'react-autosuggest';
import { Link, useNavigate } from 'react-router-dom';

import debounce from 'lodash/debounce';
import every from 'lodash/fp/every';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';

import SearchIcon from '@mui/icons-material/Search';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import { alpha, styled } from '@mui/material/styles';
import { useDebounce } from 'use-debounce';

import useApiClient from 'services/useApiClient';

import * as icons from './icons';
import SnackbarAlert from './SnackbarAlert';

const SearchBar = styled('div')`
  color: inherit;
  & .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(1, 1, 1, 0)};
    padding-left: ${({ theme }) => `calc(1em + ${theme.spacing(4)})`};
    transition: ${({ theme }) => theme.transitions.create('width')};
    width: 100%;
    ${({ theme }) => theme.breakpoints.up('sm')} {
      width: 12ch;
      &:focus {
        width: 20ch;
      }
    }
  }
  &:hover {
    background: ${({ theme }) => alpha(theme.palette.common.white, 0.25)};
  }
  & input {
    background: none;
    border: 0;
    color: inherit;
    display: block;
    font: inherit;
    margin: 0; // Reset for Safari
    padding: ${({ theme }) =>
      `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(5)}`};
    vertical-align: middle;
    white-space: normal;
    width: 100%;

    &:focus {
      outline: 2px solid #fffb;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 1.6em;
      width: 1.6em;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'><g stroke='%23FFF' stroke-width='1.2'><path d='M2 2 L8 8'/><path d='M2 8 L8 2'/></g></svg>");
    }
  }
`;

const SearchIconContainer = styled('div')`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  width: ${({ theme }) => theme.spacing(5)};
`;

const getSectionSuggestions = section => section?.suggestions;

const renderSectionTitle = section => <strong data-sentry-unmask>{section.title}</strong>;

const renderSuggestion = suggestion => {
  const iconMap = {
    salon: icons.SalonIcon,
    customer: icons.CustomerIcon,
    order: icons.OrderIcon,
    teammate: icons.TeammateIcon,
  };
  const icon = iconMap[suggestion.type];

  const pages = {
    customer: 'profile',
    order: 'formulas',
    salon: '',
  };

  return (
    <ListItemButton
      component={Link}
      to={`/${suggestion.type}s/${suggestion.pubkey}/${pages[suggestion.type]}`}
      data-testid={`${suggestion.type}-${suggestion.pubkey}`}
    >
      <ListItemIcon>{React.createElement(icon)}</ListItemIcon>
      <ListItemText
        primary={suggestion.title}
        secondary={suggestion.subtitle}
        data-testid="suggestion-title"
        sx={{ wordWrap: 'break-word' }}
      />
    </ListItemButton>
  );
};

const renderSuggestionsContainer = options => {
  const { containerProps, children } = options;
  return (
    <Paper {...containerProps} square data-testid="suggestions-container">
      {children}
    </Paper>
  );
};

const AppSearch = () => {
  const APIClient = useApiClient();
  const [searchValue, setSearchValue] = useState('');
  const [searchText] = useDebounce(searchValue, 300);
  const [notification, setNotification] = useState({
    message: null,
    severity: 'info',
  });
  const [controller, setController] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (searchText && controller?.signal) {
      const regExp = /^http/i;

      if (regExp.test(searchText)) {
        const orderItemRegex =
          /^https:\/\/prose.com\/([fp])\/([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})$/gim;
        const re = orderItemRegex.exec(searchText.toLowerCase());
        if (re) {
          (async () => {
            try {
              const prefix = re[1];
              const pubkey = re[2];
              const resp = await APIClient.get(
                `/v1/backoffice/${prefix === 'f' ? 'formulas' : 'proditems'}/${pubkey}/`
              );
              const formula = await resp.json();
              setSearchValue('');
              navigate(`/orders/${formula?.order?.pubkey}/formulas`);
            } catch (err) {
              setNotification({ message: 'Item not found', severity: 'error' });
              setSearchValue('');
            }
          })();
        }
      } else {
        (async () => {
          let results;
          try {
            // Pass the signal as a 4th value to the API get function for aborting purpose
            const orderSearch = APIClient.get(
              '/v1/backoffice/orders/',
              { q: searchText },
              null,
              controller.signal
            );
            const customerSearch = APIClient.get(
              '/v1/backoffice/customers/',
              { q: searchText },
              null,
              controller.signal
            );
            const salonSearch = APIClient.get(
              '/v1/backoffice/salons/',
              { q: searchText },
              null,
              controller.signal
            );
            const responses = await Promise.all([customerSearch, orderSearch, salonSearch]);
            results = await Promise.all(map(response => response.json(), responses));
            if (every(isEmpty, results)) {
              setNotification({ message: 'No results found', severity: 'error' });
            }
          } catch (err) {
            if (err.name === 'AbortError') {
              return;
            }
            setNotification({ message: 'Something went wrong', severity: 'error' });
            throw err;
          }

          setSuggestions([
            { title: 'Customers', suggestions: get([0], results) },
            { title: 'Orders', suggestions: get([1], results) },
            { title: 'Salons', suggestions: get([2], results) },
          ]);
        })();
      }
    }
  }, [controller, navigate, searchText, APIClient]);

  const onSuggestionsFetchRequested = useMemo(
    () =>
      debounce(() => {
        // if there is a controller setup, it means there might be an ongoing request, try to cancel it first
        if (controller) {
          controller.abort();
        }
        // then, create a new abort controller and only after (on useEffect), try to fetch with the controller signal
        setController(new window.AbortController());
      }, 100),
    [controller]
  );

  return (
    <SearchBar data-testid="search-bar">
      <SearchIconContainer>
        <SearchIcon />
      </SearchIconContainer>
      <Autosuggest
        focusInputOnSuggestionClick={false}
        getSectionSuggestions={getSectionSuggestions}
        getSuggestionValue={() => searchValue}
        inputProps={{
          onChange: event => {
            setSearchValue(event.target.value);
          },
          type: 'search',
          value: searchValue,
        }}
        multiSection
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
        }}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        renderSectionTitle={renderSectionTitle}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={suggestions}
        theme={{
          suggestionsContainerOpen: {
            left: 0,
            position: 'absolute',
            right: 0,
            zIndex: 1,
          },
          suggestion: {
            display: 'block',
          },
          suggestionsList: {
            margin: 0,
            padding: 0,
            listStyleType: 'none',
          },
        }}
      />
      <SnackbarAlert notification={notification} setNotification={setNotification} />
    </SearchBar>
  );
};

export default AppSearch;
