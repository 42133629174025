import concat from 'lodash/fp/concat';
import entries from 'lodash/fp/entries';
import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flatten from 'lodash/fp/flatten';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';

import type { Address, ProseCategory } from 'types/common';

import type { ProductionLane } from 'dux/production/types';
import type { Factories, FactoryCategory } from 'dux/teammates/types';

type Params = {
  factory?: string;
  category?: ProseCategory;
  lanes: ProductionLane[];
  teammateFactories: Factories;
  shippingAddress?: Address;
  mode?: 'noFilter';
};

type FilterParams = (
  targetCategory: FactoryCategory,
  factory: Params['factory'],
  teammatesFactory: string,
  lanes: Params['lanes'],
  mode: Params['mode'],
  state?: string
) => ProductionLane[];

const filterParams: FilterParams = (
  targetCategory,
  factory,
  teammatesFactory,
  lanes,
  mode,
  state
) => {
  return filter((lane: ProductionLane) => {
    const matchesProductionCell =
      lane?.production_cell?.name === targetCategory &&
      lane?.production_cell?.production_factory?.pubkey ===
        (factory === 'all' ? teammatesFactory[0] : factory);

    if (mode === 'noFilter' && state) {
      return matchesProductionCell && includes(state, lane.serving_location.locations);
    }

    return matchesProductionCell;
  }, lanes);
};

const filteredLanesByCategoriesAndFactories = ({
  factory,
  category, // Category from settings, need to be remove after everyone has production cells
  lanes,
  teammateFactories,
  shippingAddress,
  mode,
}: Params): ProductionLane[] => {
  // Need to be remove after everyone has production cells
  if (factory === 'all' && isEmpty(teammateFactories)) {
    if (mode === 'noFilter') {
      return lanes;
    }

    return filter({ category }, lanes);
  }

  // Handle all factories and respective categories
  const lanesByCategoryAndFactory = map(
    (teammatesFactory: string) => {
      return map((targetCategory: FactoryCategory) => {
        return filterParams(
          targetCategory,
          factory,
          teammatesFactory,
          lanes,
          mode,
          shippingAddress?.state
        );
      }, teammatesFactory[1]);
    },
    factory !== 'all'
      ? [find(item => item[0] === factory, entries(teammateFactories))]
      : entries(teammateFactories)
  );

  return factory !== 'all'
    ? flatten(lanesByCategoryAndFactory[0])
    : flatten(concat(...lanesByCategoryAndFactory));
};

export default filteredLanesByCategoriesAndFactories;
