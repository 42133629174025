import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import assign from 'lodash/fp/assign';

import { loadParam, saveParam } from 'utils/localStorage';

import type { BufferSpacePubkey } from '../bufferSpaces/types/bufferSpaces-list-types';
import type { SettingsState } from './types';

const state: SettingsState = {
  bufferScanner: 'off',
  category: 'haircare',
  selectedAutomationScreen: 'eola',
  selectedPrepackStation: 'prepack_lab1',
  selectedBufferSpace: '',
  selectedLane: '',
  viewMode: 'admin',
};

const loadedFromlocalStorage: SettingsState = loadParam('settings');
const initialState: SettingsState = assign(state, loadedFromlocalStorage);
saveParam('settings', initialState);

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateSettings(draftState, action: PayloadAction<SettingsState>) {
      const {
        category,
        bufferScanner,
        selectedLane,
        selectedBufferSpace,
        selectedPrepackStation,
        selectedAutomationScreen,
        viewMode,
      } = action.payload;
      saveParam('settings.production.defaultLab', selectedLane);

      draftState.category = category;
      draftState.bufferScanner = bufferScanner;
      draftState.selectedLane = selectedLane;
      draftState.selectedBufferSpace = selectedBufferSpace;
      draftState.selectedPrepackStation = selectedPrepackStation;
      draftState.selectedAutomationScreen = selectedAutomationScreen;
      draftState.viewMode = viewMode;
    },
    updateSelectedLane(draftState, action: PayloadAction<string>) {
      draftState.selectedLane = action.payload;
    },
    updateSelectedBufferSpace(draftState, action: PayloadAction<BufferSpacePubkey>) {
      draftState.selectedBufferSpace = action.payload;
    },
  },
});

const { reducer, actions } = settingsSlice;

export const { updateSettings, updateSelectedLane, updateSelectedBufferSpace } = actions;

export default reducer;
