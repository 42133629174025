import { useEffect } from 'react';

import keys from 'lodash/fp/keys';
import size from 'lodash/fp/size';

import { useOmsDispatch, useOmsSelector } from 'hooks/rtk';

import * as ProductionActions from 'dux/production/actions';
import * as AuthSelectors from 'dux/auth/selectors';
import * as TeammatesSelectors from 'dux/teammates/selectors';

const FactoriesHandler = () => {
  const dispatch = useOmsDispatch();

  const isSignedIn = useOmsSelector(AuthSelectors.isSignedIn);
  const factories = useOmsSelector(TeammatesSelectors.getSignedInTeammateProductionFactories);

  useEffect(() => {
    if (isSignedIn) {
      dispatch(
        ProductionActions.setFactory({
          factory: size(factories) !== 1 ? 'all' : keys(factories)[0],
          mode: '',
        })
      );
    }
  }, [dispatch, factories, isSignedIn]);

  return null;
};

export default FactoriesHandler;
